.col-sm-4 {
  padding: 10px;
  .card {
    border-radius: none !important;
    border: none;

    .card-body {
      text-align: center;
      .card-title {
        text-align: center;
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
      }
      .card-text {
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 1px;
      }
      .custom-btn {
        color: black;
        padding-bottom: 7px;
        background-color: none;
        border: none;
        border-bottom: 1px solid black;
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: none;
      }
    }
  }
}
