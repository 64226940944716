.section-image {
  text-align: center;
  background-image: url('//external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd3jkudlc7u70kh.cloudfront.net%2Ffish-fact.jpg&f=1&nofb=1');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 100%;
  height: 500px;
  padding: 0;
  margin-bottom: 6.5rem;
  .bg-shadow {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 4;
    height: 100%;
    .container {
      h1 {
        text-transform: uppercase;
        color: #fff;
        padding-top: 200px;
        font-size: 68px;
        font-weight: 600;
        letter-spacing: 2px;
      }
    }
  }
}
.about-description {
  margin: 100px 0;
}
