.item-section {
  padding: 0 0 50px;
  .container {
    .item-title {
      text-align: center;
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .item-section {
    padding: 0;
    .container {
      .item-title {
        font-size: 2.1rem;
      }
    }
  }
}
