.navbar {
  background-color: #fff;
  padding: 25px 0;
  font-size: 16px;
  .navbar-brand {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
  }
  .container {
    .navbar-style {
      .navbar-nav {
        .nav-item {
          .letter-style {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
            &:hover {
              color: #0000cc;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .navbar {
    .navbar-brand {
      padding-left: 15px;
    }
    .navbar-toggler {
      margin-right: 15px;
    }
    .container {
      .navbar-style {
        .navbar-nav {
          .nav-item {
            .letter-style {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}
