footer {
  font-weight: 500;
  background: black;
  color: #fff;
  text-align: center;
  padding: 100px 0;
  margin-top: 70px;
  letter-spacing: 1px;
  .container {
    .chat {
      font-size: 4rem;
    }
    .row {
      .col-sm-4 {
        .card {
          background-color: black;
          padding: 30px 0;
          .card-body {
            .card-title {
              font-weight: 500;
              letter-spacing: 1px;
              font-size: 1.7rem;
            }
            .card-text {
              font-size: 1.1rem;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
    .social {
      .fab {
        padding: 5px;
        color: #fff;
        &:hover {
          color: #0000cc;
        }
      }
    }
  }
  .copyright {
    letter-spacing: 1px;
    padding-top: 10px;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .row {
    .col-sm-4 {
      .card {
        padding: 0;
        // .card-body {
        //   padding: 0;
        // }
      }
    }
  }
}
