.main-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  text-align: center;
  padding: 0;
  color: #fff;
  border-radius: none;
  .bg-shadow {
    height: 100%;
    .container {
      .main-title {
        color: black;
        padding-top: 200px;
        font-size: 4rem;
        font-weight: 600;
        letter-spacing: 2px;
      }
      .main-lead {
        color: black;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
}
// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .main-image {
    .bg-shadow {
      .container {
        .main-title {
          padding-top: 170px;
          font-size: 4rem;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .main-image {
    .bg-shadow {
      .container {
        .main-title {
          padding-top: 120px;
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .main-image {
    height: 80vh;
    .bg-shadow {
      .container {
        .main-title {
          padding-top: 88px;
          font-size: 3rem;
        }
        .main-lead {
          font-size: 0.8rem;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
}
