.cards-section {
  padding: 50px 0 150px;
  .container {
    .cards-title {
      text-align: center;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .cards-section {
    padding: 50px 0 0;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}
