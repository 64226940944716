.linksTitle {
  padding: 50px 0 0;
  margin: 0;
}

.col-sm-6 {
  p {
    font-size: 1.2rem;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .col-sm-6 {
    p {
      font-size: 1.6rem;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .linksTitle {
    font-size: 3rem;
  }
  .col-sm-6 {
    p {
      font-size: 1.2rem;
    }
  }
}
