.col-sm-4 {
  padding: 1px;
  .card {
    border-radius: none !important;
    border: none;
    .rounded-0 {
      height: 350px;
      object-fit: contain;
      object-position: center;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .cardImg {
      // height: auto;
      object-fit: contain;
    }

    .card-body {
      text-align: center;
      .card-title {
        text-align: center;
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
      }
      .card-text {
        font-size: 0.9rem;
        font-weight: 400;
        letter-spacing: 1px;
      }
      .custom-btn {
        color: black;
        padding-bottom: 7px;
        background-color: none;
        border: none;
        border-bottom: 1px solid black;
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: none;
        &:hover {
          color: #0000cc;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}
